export function TicketDetails({ feedbackDetail, onReopen, onClose }) {
  return (
    <div className="space-y-4 p-6 rounded-t-lg">
      <h2 className="font-heading text-[24px] max-[768px]:text-[18px]">
        Ticket Details
      </h2>

      {/* <div className="grid gap-2 sm:max-w-[50vw] md:max-w-[30vw] lg:max-w-[20vw]">
        <div className="flex justify-between">
          <span className="text-zinc-400">Ticket ID:</span>
          <span className="text-white">{feedbackDetail?.ticketId}</span>
        </div>

        <div className="flex justify-between">
          <span className="text-zinc-400">Submitted by:</span>
          <div className="text-right">
            <div className="text-white">
              {feedbackDetail?.userData?.[0]?.firstName +
                " " +
                feedbackDetail?.userData?.[0]?.lastName || "Samantha Neupane"}
            </div>
            <div className="text-sm text-zinc-400">
              {feedbackDetail?.userData?.[0]?.email}
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <span className="text-zinc-400">Date & Time:</span>
          <span className="text-white">
            {(feedbackDetail &&
              FormatDateWithSlash(feedbackDetail?.createdAt)) ||
              "12/10/2023"}
            ,{" "}
            {(feedbackDetail && DateToPresentTime(feedbackDetail?.createdAt)) ||
              "12:39 PM"}
          </span>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-zinc-400">Status:</span>
          <div className="flex gap-2">
            <p
              className={`${
                feedbackDetail?.status === 1
                  ? "text-[#25B39E]"
                  : "text-[#FA5F55]"
              }`}
            >
              {feedbackDetail?.status === 1 ? "Resolved" : "Unresolved"}
            </p>
          </div>
        </div>
      </div> */}

      <div className="mt-[38px]  flex justify-start items-center">
        <p className="text-white/50 font-medium w-[20%]">Ticket ID:</p>
        <div className="flex  items-center gap-2 ml-[100px] w-[80%]">
          <div className="text-start">
            <p>{feedbackDetail?.ticketId}</p>
          </div>
        </div>
      </div>

      {/* <div className="mt-[48px] flex justify-between items-center">
        <p className="text-white/50 font-medium w-[20%]">Submitted by:</p>
        <div className="flex items-center gap-2 ml-[100px] w-[80%]">
          <div className="text-start">
            <p>
              {(feedbackDetail?.userData?.[0]?.firstName !== null
                ? feedbackDetail?.userData?.[0]?.firstName
                : "") +
                " " +
                (feedbackDetail?.userData?.[0]?.lastName !== null
                  ? feedbackDetail?.userData?.[0]?.lastName
                  : "")}
            </p>
            <p className="text-[12px] text-white/50">
              {feedbackDetail?.userData?.[0]?.email || ""}
            </p>
          </div>
        </div>
      </div> */}

      <div className="mt-[38px]  flex justify-start items-center">
        <p className="text-white/50 font-medium w-[20%]">Date & Time:</p>
        <div className="ml-[100px] w-[80%]">
          <p>{new Date(feedbackDetail?.createdAt).toLocaleString()}</p>
        </div>
      </div>

      <div className="mt-[38px]  flex justify-start items-center">
        <p className="text-white/50 font-medium w-[20%]">Status:</p>
        <div className="ml-[100px] w-[80%] ">
          <p
            className={`${
              feedbackDetail?.status === 1 ? "text-[#25B39E]" : "text-[#FA5F55]"
            }`}
          >
            {feedbackDetail?.status === 1 ? "Resolved" : "Unresolved"}
          </p>
        </div>
      </div>

      <div className="flex justify-end gap-2 pt-4">
        {feedbackDetail?.status === 1 ? (
          <button
            onClick={onReopen}
            className="border px-[27px] py-[8px] rounded-[8px] border-[#FBBC5E] transition-all hover:bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] hover:text-[#000] font-semibold"
          >
            Reopen Ticket
          </button>
        ) : (
          <button
            onClick={onClose}
            className="border px-[27px] py-[8px] rounded-[8px] border-[#FBBC5E] transition-all hover:bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] hover:text-[#000] font-semibold"
          >
            Close Ticket
          </button>
        )}
        {/* <Button variant="outline" onClick={onReopen}>
          Reopen Ticket
        </Button>
        <Button onClick={onClose}>Close Ticket</Button> */}
      </div>
    </div>
  );
}
