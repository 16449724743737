import React, { useState } from "react";
import AlertIcon from "assets/images/processing.png";
import PopupWrapper from "../PopupWrapper";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const TransactionCancel = ({
  open,
  onClose,
  canClose,
  goBack,
  onCancel,
  currency = "fstr",
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="px-4 py-8 flex flex-col justify-center items-center gap-8 font-body bg-[#242424] rounded-[16px]">
        <div className="h-[75px] w-[75px] rounded-full">
          <img
            className="h-full w-full object-cover"
            src={AlertIcon}
            alt="cancle"
          />
        </div>
        <p className="text-[24px] leading-[36px] text-center font-bold">
          Cancel Transaction
        </p>
        <p className="text-[16px] leading-[24px] px-4 text-center font-normal text-[#939393]">
          Are you sure you want to cancel this transaction? The payment process
          will need to be restarted.
        </p>

        <div className="flex justify-around gap-8 w-[80%]">
          <button
            className="mt-5 w-[50%]  font-extrabold text-[#fff] border-2 border-[#FFFFFF] rounded-[8px]  px-3 py-3"
            onClick={goBack}
          >
            Go Back
          </button>
          <button
            className="mt-5 w-[50%]  font-extrabold text-[#fff] bg-[#D43F33] rounded-[8px]  px-3 py-3"
            disabled={isLoading}
            onClick={() => {
              // onCancel
              setIsLoading(true);
              onCancel();
              setTimeout(() => {
                setIsLoading(false);
              }, 10000);
            }}
          >
            {isLoading ? (
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#535353",
                }}
              />
            ) : (
              "Yes, Cancel"
            )}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default TransactionCancel;
