/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "react-hot-toast";
import "assets/css/style.css";
import "assets/css/media.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GlobalStore } from "store/GlobalStore.js";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import setupEnv from "./components/setupEnv";
import { WebSocketProvider } from "services/socket";
const root = ReactDOM.createRoot(document.getElementById("root"));
/* The `root.render()` method is rendering the main component of the application, `<App />`, inside a
`<BrowserRouter>` component. The `<BrowserRouter>` component is used for client-side routing in
React applications. The `<React.StrictMode>` component is a development mode only feature that helps
identify potential problems in the application. It performs additional checks and warnings to ensure
that the code follows best practices. */
setupEnv();
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <Provider store={GlobalStore}>
        <BrowserRouter>
          <Toaster position="top-center" reverseOrder={false} />
          <WebSocketProvider>
            <App />
          </WebSocketProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
