/**
 *  * This file is part of FourthStar Add Wallet Address Model
 *------------------------------------------------------
 *@module Add-Wallet-Address
 *@developer Shiv Pandey
 */

import React, { useEffect, useState } from "react";
import { walletIcon } from "constant/Icon";
import { WalletIcon } from "@web3icons/react";
import Alert from "components/popup/Alert/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
import { useAddUserWalletAddressMutation } from "store/slicer/userProfile";
const AddWalletAddress = ({ onClose, walletAdd }) => {
  const [walletAddress, setWalletAddress] = useState("");
  const [labelText, setLabelText] = useState("");
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);

  const [addUserWalletAddress, { isLoading }] =
    useAddUserWalletAddressMutation();

  // Close add wallet popup box
  const onCloseConfirmPopup = () => {
    onClose();
    walletAdd(true);
    setLabelText("");
    setSelectedIcon("");
    setWalletAddress("");
  };

  const onClickDropDown = (e) => {
    e.stopPropagation();
    setShowDropDown((prev) => !prev);
  };

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  // to close dropdown on outside click
  useEffect(() => {
    const hideDropDown = () => {
      setShowDropDown(false);
    };

    document.addEventListener("click", hideDropDown);

    return () => document.removeEventListener("click", hideDropDown);
  }, []);

  // Add Wallet Address
  const handleSubmit = async (e) => {
    if (!walletAddress) {
      setAlertMessage("Wallet Address is required");
      setSuccessAlert(false);
      return;
    }

    let body = { walletId: walletAddress };
    if (labelText) body = { ...body, label: labelText };

    if (selectedIcon) body = { ...body, icon: selectedIcon };

    await addUserWalletAddress(body)
      .then((res) => {
        // console.log("RESULT: ", res);
        if (res?.data?.response?.statusEnum === 1) {
          setAlertMessage(res?.data?.response?.message);
          setSuccessAlert(true);
          onCloseConfirmPopup();
        } else if (res?.data?.response?.statusEnum === 0) {
          setAlertMessage(res?.data?.response?.message);
          setSuccessAlert(false);
        } else {
          setAlertMessage("Invalid Wallet Address");
          setSuccessAlert(false);
        }
      })
      .catch((error) => {
        setAlertMessage("Api Error Occured.");
        setSuccessAlert(false);
      });
  };

  return (
    <>
      <div className="flex bg-[#00000099] w-[100%] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[99999] outline-none focus:outline-none">
        Overlay
      </div>

      <div className="hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[99999] outline-none focus:outline-none max-[768px]:p-[20px]">
        <div className="bg-[#242424] __modal__container w-[38%] max-[768px]:w-[100%] max-[900px]:w-[75%] h-auto max-[1024px]:w-[70%] max-[1365px]:w-[55%] max-[1621px]:w-[45%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          <div className="__modal_close_icon_container flex justify-end p-[10px] pb-0">
            <div
              onClick={onClose}
              className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[40px] h-[40px]"
            >
              <img
                src={ModalCloseIcon}
                className="w-[15px] h-[15px]"
                alt="modal_close_btn"
              />
            </div>
          </div>

          <div className="relative bg-[#242424] rounded-[8px] py-4 px-8 max-[599px]:px-4">
            <h1 className="font-heading text-center font-normal text-[20px] leading-[25px] text-[#FFFFFF]">
              Add Web3 Wallets
            </h1>
            <p className="mt-8 font-body font-normal text-[18px] text-[#939393] text-center  leading-[24px]">
              Connect your Web3 wallets to enable seamless FSTR payments.
            </p>

            {/* {openInputForm && ( */}
            <>
              <div className="mt-8 flex items-center justify-center">
                <div className="flex w-full px-4 max-[1365px]:px-0 flex-col gap-y-[5px]">
                  <input
                    className={`bg-transparent w-full rounded-[8px] border border-[#535353] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none placeholder-[#666262]`}
                    type="text"
                    name="walletAddress"
                    placeholder="Enter wallet address"
                    required
                    onChange={(e) => setWalletAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className="mt-4 flex px-4 max-[1365px]:px-0 max-[599px]:flex-col max-[599px]:gap-4  items-center justify-between">
                <div
                  className=" flex w-[30%] max-[599px]:w-full justify-between items-center px-2 py-2 border border-white/50 rounded-[8px] cursor-pointer relative"
                  onClick={onClickDropDown}
                >
                  <p className="text-[14px] leading-[23.85px] text-[#ffffff]">
                    {selectedIcon || "Select Icon"}
                  </p>
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      style={{ color: "#929292" }}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.50178 4.16585H17.5018C17.6536 4.16632 17.8025 4.20821 17.9323 4.28699C18.0622 4.36578 18.1681 4.47848 18.2386 4.61297C18.3092 4.74746 18.3417 4.89865 18.3327 5.05025C18.3237 5.20185 18.2736 5.34813 18.1876 5.47335L10.6876 16.3067C10.3768 16.7558 9.62845 16.7558 9.31678 16.3067L1.81678 5.47335C1.72997 5.34839 1.67906 5.20204 1.66958 5.05018C1.66011 4.89833 1.69243 4.74678 1.76304 4.61201C1.83365 4.47723 1.93984 4.36438 2.07008 4.28573C2.20032 4.20707 2.34963 4.16561 2.50178 4.16585Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {showDropDown && (
                    <div className="__DROPDOWN_OPTIONS__ absolute w-full top-[104%] left-0 bg-[#2b2724] rounded-[8px] h-[22vh] max-[599px]:h-[30vh] overflow-auto">
                      {walletIcon.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center hover:bg-[#FBBC5E] hover:bg-opacity-10  hover:text-[#FBBC5E] rounded-[8px] pl-2"
                            onClick={() => setSelectedIcon(item?.icon)}
                          >
                            <WalletIcon name={item?.icon} variant="branded" />
                            <span className="py-2 px-2 text-sm leading-[23.85px] font-medium text-white/80 hover:text-[#fbbc5e]">
                              {item?.label}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="flex w-[67%] max-[599px]:w-full flex-col gap-y-[5px]">
                  <input
                    className={`bg-transparent  rounded-[8px] border border-[#535353] py-[10px] px-[15px] focus:border-[#f6f6f6] outline-none placeholder-[#666262]`}
                    type="text"
                    name="label"
                    placeholder="Label (optional) "
                    onChange={(e) => setLabelText(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-[30px] mb-[40px] flex justify-center gap-x-[4%]">
                <button
                  onClick={handleSubmit}
                  className="px-2 w-[20%] flex justify-center  py-3 text-center font-bold rounded-lg text-black bg-gradient-to-r from-[#FBBC5E] to-[#F3A151] max-[599px]:w-[48%] hover:from-[#DB9149] hover:to-[#DB9149]"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#535353",
                      }}
                    />
                  ) : (
                    "Add"
                  )}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default AddWalletAddress;
