export const walletIcon = [
  {
    label: "MetaMask",
    icon: "metamask",
  },
  {
    label: "wallet-connect",
    icon: "wallet connect",
  },
  {
    label: "trust-wallet",
    icon: "trust",
  },
  {
    label: "phantom",
    icon: "phantom",
  },
  {
    label: "coinbase",
    icon: "coinbase wallet",
  },
  {
    label: "alfa1",
    icon: "alfa1",
  },
  {
    label: "argent x",
    icon: "argent",
  },
  {
    label: "backpack",
    icon: "backpack",
  },
  {
    label: "coin98",
    icon: "coin98",
  },

  {
    label: "enkrypt",
    icon: "enkrypt",
  },
  {
    label: "imtoken",
    icon: "imToken",
  },
  {
    label: "kraken",
    icon: "kraken wallet",
  },
  {
    label: "argent x",
    icon: "argent",
  },
  {
    label: "lit",
    icon: "lit protocol",
  },
  {
    label: "obvious",
    icon: "obvious wallet",
  },
  {
    label: "okx",
    icon: "okx wallet",
  },

  {
    label: "portal",
    icon: "portal",
  },
  {
    label: "rabby",
    icon: "rabby",
  },
  {
    label: "rainbow",
    icon: "rainbow",
  },
  {
    label: "safe",
    icon: "safe wallet",
  },
  {
    label: "ledger",
    icon: "ledger",
  },
  {
    label: "soul",
    icon: "soul",
  },
  {
    label: "token-pocket",
    icon: "token pocket",
  },
  {
    label: "trezor",
    icon: "trezor",
  },
  {
    label: "unipass",
    icon: "unipass",
  },
  {
    label: "venly",
    icon: "venly",
  },
  {
    label: "zengo",
    icon: "zengo",
  },
  {
    label: "zerion",
    icon: "zerion",
  },
  {
    label: "alpha-wallet",
    icon: "alpha wallet",
  },
  {
    label: "my-ether-wallet",
    icon: "MyEtherWallet",
  },
  {
    label: "pillar",
    icon: "Pillar",
  },
  {
    label: "ronin",
    icon: "ronin",
  },
  {
    label: "sender",
    icon: "sender",
  },
  {
    label: "sequence",
    icon: "sequence",
  },
  {
    label: "squads",
    icon: "squads",
  },
  {
    label: "wallet-3",
    icon: "wallet3",
  },
  {
    label: "xdefi",
    icon: "xdefi",
  },
  {
    label: "clave",
    icon: "clave",
  },
];
