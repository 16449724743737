import React from "react";
import PopupWrapper from "../PopupWrapper";
import CrossIcon from "assets/images/red_cross.png";
import CheckIcon from "assets/images/check_icon.png";
import ErrorCrossIcon from "assets/images/alert_icon.png";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
// import PendingIcon from "assets/images/loading.png";
import PendingIcon from "assets/images/processingpending.png";

const Alert = ({
  open,
  onClose,
  canClose,
  heading,
  message,
  isSuccess = true,
  buttonText = "OK",
  showCloseIcon = false,
  onClickCloseIcon,
}) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="pt-8 pb-8 px-12 max-sm:px-4 rounded-lg bg-[#262626] relative">
        {showCloseIcon && (
          <div
            onClick={onClickCloseIcon}
            className="bg-[#FFFFFF1A] hover:bg-[#FFFFFF4D] absolute top-[-15px] right-[-25px] hover:cursor-pointer flex flex-col justify-center items-center rounded-[50%] w-[40px] h-[40px]"
          >
            <img
              src={ModalCloseIcon}
              className="w-[15px] h-[15px]"
              alt="modal_close_btn"
            />
          </div>
        )}
        <div className="__ICON_CONTAINER__ grid place-content-center">
          {isSuccess == true ? (
            <div className="h-[70px] w-[70px] rounded-full">
              <img
                className="h-full w-full object-cover"
                src={CheckIcon}
                alt="processing"
              />
            </div>
          ) : isSuccess == "pending" ? (
            <div className="h-[70px] w-[70px] rounded-full">
              <img
                className="h-full w-full object-cover"
                src={PendingIcon}
                alt="processing"
              />
            </div>
          ) : (
            <div className="h-[70px] w-[70px] rounded-full">
              <img
                className="h-full w-full object-cover"
                src={ErrorCrossIcon}
                alt="processing"
              />
            </div>
          )}
        </div>
        {isSuccess == true && (
          <p className="__HEADING__ mt-8 text-xl font-heading text-center">
            {heading}
          </p>
        )}
        {isSuccess == "pending" && (
          <p className="__HEADING__ mt-8 text-xl font-heading text-center">
            {heading}
          </p>
        )}
        {isSuccess == "error" && heading && (
          <p className="__HEADING__ mt-8 text-xl font-heading text-center">
            {heading}
          </p>
        )}
        <p
          className={`mt-8 font-body text-center ${
            isSuccess ? "text-md" : "text-lg"
          }`}
        >
          {message}
        </p>
        <div className="__ACTION_BTN_CONT__">
          <button
            className={`mt-8 mx-auto font-bold rounded-lg btn-gradient text-black py-3 px-12 flex justify-center items-center`}
            type="button"
            onClick={onClose}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default Alert;
