/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module user
 *@developer Sameer <sameer@shadowcast.io>
 */

import { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import { createTheme } from '@mui/material';

import { routes } from "./routes";
import ReactGA from "react-ga4";
import axios from "axios";

import "./globals.css";
import { Helmet } from "react-helmet";
// import Header from 'components/header/Index.jsx';
// import SideBar from 'components/sidebar/Index.jsx';
// import Layout from 'layout/Index.jsx';
// import Footer from 'components/footer/Index.jsx';

/**
 * The App function is a React component that renders a sidebar and routes based on the current
 * pathname.
 * @returns The App component is returning JSX code. It includes a SideBar component with children that
 * consist of a Routes component. Inside the Routes component, there are multiple Route components
 * being rendered based on the result of the getRoutes function. Additionally, there is a Route
 * component with a path of "/*" that renders a Navigate component.
 */

function App() {
  const { pathname } = useLocation();
  const currentEnv = process.env.REACT_APP_ENVIRONMENT;
  const [accessGranted, setAccessGranted] = useState(
    currentEnv !== "main" ? null : true
  );
  const GOOGLE_ANALYTICS_MEASUREMENT_ID =
    process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      return (
        <Route path="/" element={route.useLayout} key={route.key}>
          <Route path={route.route} element={route.component} />
        </Route>
      );
    });

  const UID = "userID" + JSON.parse(localStorage.getItem("userInfo"))?.id;
  // useEffect(() => {
  //   console.log("hgdgfd", UID);
  // }, [UID]);

  useEffect(() => {
    // console.log("GOOGLE_ANALYTICS_MEASUREMENT_ID=============>",GOOGLE_ANALYTICS_MEASUREMENT_ID)
    ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID, {
      gaOptions: {
        userId: UID,
      },
    });
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
    });

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);
  useEffect(() => {
    if (currentEnv !== "main") {
      const fetchIPList = async () => {
        const { data: ipList } = await axios.get(
          `https://fourthstar-staticcontent.s3.us-east-1.amazonaws.com/whilistedIP.json`
        );
        const { data: ipInfo } = await axios.get(
          "https://api.ipify.org?format=json"
        );

        const allowedIPs = ipList[currentEnv];
        const clientIP = ipInfo.ip;

        if (allowedIPs.includes(clientIP)) {
          setAccessGranted(true);
        } else {
          setAccessGranted(false);
        }
      };

      fetchIPList();
    }
  }, []);

  if (accessGranted === null) return <p>Loading...</p>;
  if (!accessGranted) return <p>403 - Access Denied</p>;
  if (process.env.REACT_APP_CONSOLE_LOGS == "false") {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
    console.debug = () => {};
  }
  const getHelmetTitle = () => {
    switch (true) {
      // Specific routes
      case pathname === "/":
        return "Journey Beyond The Stars";

      case pathname === "/signin":
        return "Sign In";

      case pathname === "/signup":
        return "Sign Up";

      case pathname === "/forgot-password":
        return "Forgot Password";

      case pathname === "/me":
        return "Profile";

      case pathname === "/settings":
        return "Settings";

      case pathname === "/wallet":
        return "Wallet";

      case pathname === "/my-assets/subscriptions":
        return "Manage Subscriptions";

      case pathname === "/my-assets":
        return "Assets";

      case pathname === "/friends":
        return "Friends";

      case pathname.startsWith("/help-center"):
        return "Help Center";

      case pathname === "/me/all/notification":
        return "Notifications";

      case pathname === "/cart":
        return "Cart";

      case pathname === "/checkout":
        return "Checkout";

      case pathname === "/sidequest":
        return "Download";
      // Marketplace routes (more specific routes first)
      case pathname.startsWith("/marketplace/entertainment/events"):
        return "Marketplace Events";

      case pathname.startsWith("/marketplace/entertainment/channels"):
        return "Marketplace Channels";

      case pathname.startsWith("/marketplace/entertainment"):
        return "Marketplace Entertainment";

      case pathname.startsWith("/marketplace/ships"):
        return "Marketplace Ships";

      case pathname.startsWith("/marketplace/apartments"):
        return "Marketplace Apartments";

      case pathname.startsWith("/marketplace/avatars"):
        return "Marketplace Avatars";

      case pathname.startsWith("/marketplace/clothing"):
        return "Marketplace Clothing";

      case pathname.startsWith("/marketplace/companions"):
        return "Marketplace Companions";

      case pathname.startsWith("/marketplace/gaming"):
        return "Marketplace Games";

      case pathname.startsWith("/marketplace"):
        return "Marketplace";

      // Default
      default:
        return "Journey Beyond The Stars";
    }
  };

  return (
    <>
      {/* Use Helmet to dynamically set the title */}
      <Helmet>
        <title>Fourth Star | {getHelmetTitle()}</title>
        <meta
          name="description"
          content="The first VR Metaverse, combining immersive content and gaming. Designed for players and built for the next generation of content creators. Experience immersive film, music, and gaming all in one platform."
        />
        <meta name="author" content="fourthstar.com" />
      </Helmet>

      {/* <SideBar
        children={
          <Routes>
            {getRoutes(routes())}
            <Route path="/*" element={<Navigate replace to="/" />} />
          </Routes>
        }
      ></SideBar> */}
      {/* <Layout
        children={
          <Routes>
            {getRoutes(routes)}
            <Route path="/*" element={<Navigate replace to="/" />} />
          </Routes>
        }
      /> */}
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="/*" element={<Navigate replace to="/" />} /> */}
      </Routes>
    </>
  );
}

export default App;
