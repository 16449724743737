/**
 *  * This file is part of FourthStar Wallet Address Model
 *------------------------------------------------------
 *@module Wallet-Address-List
 *@developer Shiv Pandey
 */

import React, { useEffect, useState } from "react";
import { WalletIcon } from "@web3icons/react";
import defaultWalletIcon from "assets/icons/default-wallet-icon.png";
import AddWalletAddress from "./AddWalletAddress";

const WalletAddressModel = ({
  addressList,
  onCancel,
  onNext,
  btnText = "Next",
  selectedWalletId,
}) => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectWalletAddress, setSelectWalletAddress] = useState("");

  //Close Add wallet address box
  const onCloseConfirmPopup = () => {
    setShowConfirmPopup(false);
  };

  useEffect(() => {
    setSelectWalletAddress(
      selectedWalletId ? selectedWalletId : addressList[0]?.walletId
    );
    
  }, [addressList]);

  return (
    <>
      {showConfirmPopup && <AddWalletAddress onClose={onCloseConfirmPopup} />}
      <div className="flex bg-[#00000099] w-[100%] opacity-100 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9998] outline-none focus:outline-none">
        Overlay
      </div>

      <div className="hs-overlay flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[9999] outline-none focus:outline-none max-[768px]:p-[20px]">
        <div className="bg-[#242424] __modal__container w-[38%] max-h-[75vh] max-[768px]:max-h-[80vh] max-[768px]:w-[100%] max-[900px]:w-[75%] h-auto max-[1024px]:w-[70%] max-[1365px]:w-[55%] max-[1621px]:w-[45%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          <div className="relative bg-[#242424] rounded-[8px] py-4 px-8 max-[599px]:px-4">
            <div className="mt-8 pb-[20px] flex items-center justify-between max-[599px]:p-[10px] ">
              <p className="font-body  text-[16px] font-bold text-[#FFFFFF]  leading-[24px] max-[599px]:text-[14px]">
                Select Web3 Wallet
              </p>
              <button
                onClick={() => {
                  setShowConfirmPopup(true);
                }}
                className="bg-[#F3A151] text-[#000] text-[16px] max-[768px]:text-[14px] font-semibold w-[20%] rounded-[8px] py-[10px] max-[599px]:w-[40%]"
              >
                Add wallet
              </button>
            </div>
            <div className="mt-[10px] mb-[20px] flex flex-col gap-y-[15px] h-[50vh] max-[768px]:h-[60vh] overflow-y-auto pr-3 max-[599px]:pr-[5px] showScrollbar">
              {addressList.length > 0 ? (
                addressList.map((address, i) => {
                  return (
                    <div
                      key={address?.walletId}
                      onClick={() => {
                        setSelectWalletAddress(address?.walletId);
                      }}
                      className=" bg-[#65656533] flex items-center justify-between rounded-[10px] gap-[20px] p-[15px] max-[599px]:p-[15px] pl-3"
                    >
                      {/* <label className="inline-flex items-center"> */}
                      <div className="flex gap-[20px] max-[599px]:gap-[10px] items-center">
                        <div className="min-h-[28px] min-w-[28px] h-[1.7rem] w-[1.7rem] flex items-center justify-center rounded-full border-2 border-white bg-gray-800">
                          {selectWalletAddress?.toLowerCase() == address?.walletId?.toLowerCase() ? (
                            <span className="inline-block h-[75%] w-[75%] bg-teal-500 rounded-full"></span>
                          ) : (
                            <span className="inline-block h-[75%] w-[75%] bg-gray-800 rounded-full"></span>
                          )}
                        </div>

                        <span className="rounded-full border-2 border-[#535353] bg-black  p-1 ">
                          {address?.icon ? (
                            <WalletIcon
                              size="45"
                              name={`${address?.icon}`}
                              variant="branded"
                            />
                          ) : (
                            <img
                              src={defaultWalletIcon}
                              className="w-[45px] h-[45px]"
                            />
                          )}
                        </span>
                        <div className="flex flex-col items-start justify-start">
                          <p className="font-semibold text-[16px]">
                            {`${address?.label ? address?.label : "_"}`}
                          </p>
                          <p className="text-[12px] text-white/50 break-words break-all">
                            {`${address?.walletId ? address?.walletId : ""}`}
                          </p>
                        </div>
                      </div>
                      {/* </label> */}
                    </div>
                  );
                })
              ) : (
                <div className="text-white/70 text-lg text-center h-[400px] grid place-content-center">
                  No wallet added
                </div>
              )}
            </div>
            <div className="__form_btn_group flex justify-center mt-[40px] mb-[40px] gap-[30px] max-[768px]:my-3">
              <button
                onClick={onCancel}
                className="border text-[16px] max-[768px]:text-[14px] font-medium w-[40%] rounded-[8px] py-[15px]"
              >
                Cancel
              </button>
              <button
                onClick={() => onNext(selectWalletAddress)}
                className="bg-[#F3A151] text-[#000] text-[16px] max-[768px]:text-[14px] font-semibold w-[40%] rounded-[8px]"
              >
                {btnText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletAddressModel;
