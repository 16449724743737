/**
 *@module CheckoutFstr-popup
 *@developer Shiv Pandey
 */

import React from "react";
import PopupWrapper from "../PopupWrapper";
import { Link } from "react-router-dom";
import MetaMaskIcon from "assets/images/metamask-icon.png";

const CheckoutFstr = ({
  open = true,
  onClose,
  canClose,
  message,
  heading,
  btnText,
  btnAction,
}) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="px-4 py-8 flex flex-col justify-center items-center gap-4 font-body bg-[#242424] rounded-[16px]">
        <div className="mt-2 w-fit rounded-full border border-[#898989] p-4 mx-auto">
          <img
            className="w-[56px] h-[52px] object-contain"
            src={MetaMaskIcon}
            alt="metamask-icon"
          />
        </div>
        {heading && (
          <h2 className="font-body font-semibold text-xl text-center">
            {heading}
          </h2>
        )}

        <p className="text-[16px] leading-[24px] text-center font-normal text-white">
          {message || ""}
        </p>
        <button
          className="btn-gradient font-bold text-black rounded-[6px] px-6 py-3"
          onClick={btnAction}
        >
          {btnText}
        </button>
        <Link
          to={`/marketplace`}
          className="mt-2 font-semibold text-[16px] leading-[19.36px] text-center text-[#fbbc5e]/70 hover:text-[#fbbc5e]"
        >
          Go back to Marketplace
        </Link>
      </div>
    </PopupWrapper>
  );
};

export default CheckoutFstr;
