import { createContext, useEffect, useRef, useState, useContext } from "react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const publicSocket = useRef(null);
  const privateSocket = useRef(null);

  const [isConnected, setIsConnected] = useState(null);
  const SOCKET_URL = process.env.REACT_APP_BASE_CREATOR_API_URL;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAuthToken(token);

    return () => {
      // Cleanup on unmount
      publicSocket.current?.disconnect();
      privateSocket.current?.disconnect();
    };
  }, []);

  // Initialize the public WebSocket connection with a 3-second delay
  useEffect(() => {
    if (!authToken) {
      const timeoutId = setTimeout(() => {
        const newSocket = io(SOCKET_URL, {
          transports: ["websocket"],
        });

        newSocket.on("connect", () => {
          console.log("Public Socket connection established!");
          setIsConnected(true);
        });

        const userId = uuidv4();
        newSocket.emit("connect_user_by_web", {
          userId: userId,
          userType: "guest",
        });

        newSocket.on("disconnect", () => {
          console.log("Public Socket disconnected.");
          setIsConnected(false);
        });

        publicSocket.current = newSocket;
      }, 3000); // Delay the connection by 3 seconds

      return () => {
        clearTimeout(timeoutId);
        publicSocket.current?.disconnect();
      };
    }
  }, [authToken]);

  // Initialize the private WebSocket connection with a 3-second delay
  useEffect(() => {
    if (authToken) {
      const timeoutId = setTimeout(() => {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const userId = userInfo?.id;

        const newSocket = io(SOCKET_URL, {
          auth: { authorization: `Bearer ${authToken}`, role: "user" },
          transports: ["websocket"],
        });

        newSocket.on("connect", () => {
          console.log("Auth Socket connection established!");
          setIsConnected(true);
        });

        newSocket.emit("connect_user_by_web", {
          userId: userId,
          userType: "normal",
        });

        newSocket.on("connect_user_by_web", (data) => {
          console.log("Connected to user:", data);
        });

        newSocket.on("disconnect", () => {
          console.log("Auth Socket disconnected.");
          setIsConnected(false);
        });

        newSocket.on("connect_error", (error) => {
          console.error("Socket connection error:", error);
        });

        privateSocket.current = newSocket;
      }, 3000); // Delay the connection by 3 seconds

      return () => {
        clearTimeout(timeoutId);
        privateSocket.current?.disconnect();
      };
    }
  }, [authToken]);

  // Update token and manage socket connections
  const setToken = (token) => {
    if (token) {
      publicSocket.current?.disconnect(); // Disconnect public socket on login
    } else {
      privateSocket.current?.disconnect(); // Disconnect private socket on logout
    }
    setAuthToken(token);
  };

  return (
    <WebSocketContext.Provider
      value={{
        socket: authToken ? privateSocket.current : publicSocket.current,
        setToken, // Expose function to update token
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use WebSocket context
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
