import React from "react";
import { useNavigate } from "react-router-dom";
import Explore from "../Explore";
import ChannelItem from "../ChannelItem";

import { useGetSubscriptionsListQuery } from "store/slicer/MyAssets/Subscriptions";

import dateFormat from "dateformat";
import Loader from "components/Loader/Loader";

const CancelledSubscriptions = () => {
  const navigate = useNavigate();
  let { data, isFetching, isSuccess, isError } = useGetSubscriptionsListQuery({
    page: 1,
    limit: 9999,
  });

  if (!isFetching && data?.response?.statusEnum == 1) {
    data = data?.response?.data?.subscribedUsers;
    data = data?.filter((item) => {
      return item?.status == "cancelled" || item?.status == "expired";
    });

    data = data.map((item) => {
      return {
        id: item?.subscription?._id,
        channelThumb: item?.channelThumbnail,
        channelName: item?.channelName,
        channelHandle: item?.channelHandle,
        channelId: item?.channelId,
        subscriptionStatus: item?.subscription?.subscriptionType,
        paymentMethod: item?.currency?.toLowerCase() == "usd" ? "usd" : "fstr",
        price: item?.price,
        lastPaymentStatus: item?.paymentStatus,
        renewalDate: dateFormat(
          new Date(item?.renewalDate || ""),
          "mm/dd/yyyy"
        ),
        dueDate: dateFormat(
          new Date(item?.dueDate != undefined ? item?.dueDate : 0),
          "mm/dd/yyyy"
        ),
        expiredDate: dateFormat(
          new Date(item?.expiredDate != undefined ? item?.expiredDate : 0),
          "mm/dd/yyyy"
        ),
        cancelled:
          item?.subscription?.subscriptionType == "cancelled" ||
          item?.subscription?.subscriptionType == "expired",
      };
    });
  } else {
    data = [];
    // isError = true;
  }

  if (isFetching) {
    return (
      <div className="min-h-screen grid place-content-center">
        <Loader />
      </div>
    );
  }

  // if (!isFetching && isError) {
  //   return (
  //     <div className="min-h-screen grid place-content-center">
  //       <p className="text-white/70 text-[16px] leading-[21px]">
  //         Something went wrong
  //       </p>
  //     </div>
  //   );
  // }

  if (!isFetching && data?.length === 0) {
    return (
      <div className="min-h-screen grid place-content-center">
        <div className="mt-20 w-full lg:max-w-[500px] mx-auto">
          <Explore active={false} />
        </div>
      </div>
    );
  }

  const onRenewSubscription = (id) => {
    console.log("Renew for subscription ", id);
    navigate(`/initiatepayment?isSubscription=true&channelId=${id}`);
  };

  return (
    // <div className="mt-20 w-full lg:max-w-[500px] mx-auto">
    //   <Explore active={false} />
    // </div>
    <div className="__ITEMS_MAIN_CONTAINER__ mt-3">
      <div className="flex justify-start items-center py-4 px-2 [&>p]:w-1/4 bg-[#2E2E2E]">
        <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium">
          Channel Subscribed
        </p>
        <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium">
          Subscription Details
        </p>
        <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium">
          Last Auto-debit Status
        </p>
        <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium text-center">
          Actions
        </p>
      </div>
      {data.map((item, index) => (
        <ChannelItem
          key={index}
          {...item}
          payRenewBtnHandler={onRenewSubscription}
        />
      ))}
    </div>
  );
};

export default CancelledSubscriptions;
