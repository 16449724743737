import wallet_ui from "assets/images/wallet_ui.png";
import PopupWrapper from "components/popup/PopupWrapper";
import { WalletIcon } from "@web3icons/react";
import { useEffect, useState } from "react";
import ModalCloseIcon from "assets/images/myassets/charm_cross.png";
import { walletIcon } from "constant/Icon";
import Alert from "components/popup/Alert/Alert";
import ShadowFrame from "components/shadow-frame";
import Loader from "components/Loader/Loader";
import defaultWalletIcon from "../../assets/icons/default-wallet-icon.png";
import {
  useGetUserWalletListQuery,
  useAddUserWalletAddressMutation,
  useRemoveUserWalletAddressMutation,
} from "store/slicer/userProfile";
import ErrorAlert from "utils/errorAlert";
import CircularProgress from "@mui/material/CircularProgress";
import AddWalletAddress from "components/Modal/Payments/AddWalletAddress";
const Index = () => {
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);
  const [addressList, setAddressList] = useState([]);
  const [removeWalletId, setRemoveWalletId] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [removeWalletAddressId, { isLoading: removeLoading }] =
    useRemoveUserWalletAddressMutation();

  const onCloseConfirmPopup = () => {
    setShowConfirmPopup(false);
  };

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
  };

  //Remove Wallet Addres list
  const removeWalletAddress = async (walletAddress) => {
    // console.log("Remove Wallet Address", walletAddress);
    setRemoveWalletId(walletAddress);
    await removeWalletAddressId({
      walletId: walletAddress,
    })
      .then((res) => {
        if (res?.data?.response?.statusEnum === 1) {
          setAlertMessage(res?.data?.response?.message);
          setSuccessAlert(true);
        } else {
          setAlertMessage("Invalid Wallet Address");
          setSuccessAlert(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Api Error Occured.");
        setSuccessAlert(false);
      });
  };

  // Get Wallet Addres list
  const {
    data: addressLists,
    error,
    // isFetching: loading,
  } = useGetUserWalletListQuery();
  useEffect(() => {
    if(addressLists?.response?.data?.web3Wallets){
      setIsLoading(false);
    }
    setAddressList(addressLists?.response?.data?.web3Wallets);
  }, [addressLists]);

  if (isLoading) {
    return (
      <div className="relative w-full min-h-screen flex flex-col justify-center items-center">
        <Loader />
        <ShadowFrame className="w-[250px] md:w-[400px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[60%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full flex flex-col justify-center items-center">
      {addressList && addressList.length > 0 ? (
        <div className="bg-[#242424] __modal__container w-[40%] max-[1459px]:w-[45%] max-[768px]:w-[100%] h-[70vh] overflow-auto max-[768px]:h-[90vh] max-[1199px]:w-[70%] rounded-[8px] transition-all hs-overlay-open:opacity-100 hs-overlay-open:duration-500">
          <div className="relative bg-[#242424] rounded-[8px] py-4 px-8 max-[599px]:px-4">
            <div className="mt-8 pb-[20px] flex items-center justify-between max-[599px]:p-[10px] ">
              <p className="font-body  text-[16px] font-bold text-[#FFFFFF]  leading-[24px] max-[599px]:text-[14px]">
                List of added wallets
              </p>
              <button
                className="w-[26%] max-[599px]:w-[40%]  btn-outline rounded-[8px] font-Inter font-semibold text-[12px] max-[599px]:text-[10px] leading-[19.36px] py-2 border border-[#fbbc5e] hover:text-black"
                onClick={() => {
                  setShowConfirmPopup(true);
                }}
              >
                + Add Wallet Address
              </button>
            </div>
            <div className="mt-[10px] mb-[20px] flex flex-col gap-y-[15px] h-[50vh] max-[768px]:h-[72vh] overflow-y-auto pr-3 max-[599px]:pr-[5px] showScrollbar">
              {addressList.length > 0 ? (
                addressList.map((address, i) => {
                  return (
                    <div
                      key={address?.walletId}
                      className=" bg-[#65656533] flex items-center justify-between rounded-[10px] gap-[20px] p-[15px] max-[599px]:p-[15px] pl-3"
                    >
                      <div className="flex gap-[20px] max-[599px]:gap-[10px] items-center">
                        <span className="rounded-full border-2 border-[#535353] bg-black  p-1 ">
                          {address?.icon ? (
                            <WalletIcon
                              size="30"
                              name={`${address?.icon}`}
                              variant="branded"
                            />
                          ) : (
                            <img
                              src={defaultWalletIcon}
                              className="w-[30px] h-[30px]"
                            />
                          )}
                        </span>
                        <div className="flex flex-col items-start justify-start">
                          <p className="font-semibold text-[16px]">
                            {`${address?.label ? address?.label : "_"}`}
                          </p>
                          <p className="text-[12px] text-white/50 break-words break-all">
                            {`${address?.walletId ? address?.walletId : ""}`}
                          </p>
                        </div>
                      </div>
                      <span
                        onClick={() => {
                          removeWalletAddress(address?.walletId);
                        }}
                        className="text-[#FBBC5E] font-semibold text-[12px] cursor-pointer"
                      >
                        {/* {isLoading ? "" : "Remove"} */}
                        {removeLoading &&
                        address?.walletId == removeWalletId ? (
                          <CircularProgress
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#535353",
                              marginRight: "15px",
                            }}
                          />
                        ) : (
                          "Remove"
                        )}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="text-white/70 text-lg text-center h-[400px] grid place-content-center">
                  No wallet added
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <img
            src={wallet_ui}
            className="max-w-[276px] h-auto mt-[-100px]"
            alt="Network problem"
          />
          <h1 className="font-heading font-normal text-[20px] max-[599px]:text-[18px] leading-[10px] text-white">
            Connect your Web3 Wallets
          </h1>
          <p className="text-center font-body font-normal mt-10 text-[16px] leading-[24px]">
            You haven’t added any wallets yet. Connect your Web3 wallets to
            manage <br />
            your FSTR payments effortlessly.
          </p>
          <button
            onClick={() => {
              setShowConfirmPopup(true);
            }}
            className="mt-12 btn-gradient block mx-auto text-black font-bold text-[16px] leading-[19.5px] rounded-[6px] px-6 py-3 text-center"
          >
            Get Started
          </button>
        </>
      )}

      {showConfirmPopup && <AddWalletAddress onClose={onCloseConfirmPopup} />}

      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </div>
  );
};

export default Index;
