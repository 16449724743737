import React, { useEffect, useState } from "react";
import ShadowFrame from "components/shadow-frame";
import { Link, NavLink, useNavigate } from "react-router-dom";
import CartItemThumb from "assets/images/cart_item_thumb.png";
import CoinIcon from "assets/images/coin-icon.png";
import {
  useGetCartItemByTokenQuery,
  useGetStarCartItemByTokenQuery,
} from "store/slicer/Cart";
import Loader from "components/Loader/Loader";
import { useDeleteCartItemByTokenMutation } from "store/slicer/Cart";
import MiniLoader from "../../assets/icons/spinner-white.svg";
import Alert from "components/popup/Alert/Alert";
import CartFstrIcon from "assets/images/cart_fstr_icon.png";
import CartUsdIcon from "assets/images/cart_usd_icon.png";
import { useGetUserWalletListQuery } from "store/slicer/userProfile";
import WalletAddressModel from "components/Modal/Payments/WalletAddressModel";
import AddWalletAddress from "components/Modal/Payments/AddWalletAddress";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-My-Transaction-Component
 *@developer Shubham K, Shiv Pandey
 */

// const dummyData = [
//   {
//     title: "Experience Cool Breeze",
//     thumb_url: CartItemThumb,
//     creator_handle: "@SonicSketchEntertainment",
//     category: "Volumetric",
//     price: "24.00",
//     id: "1",
//     creator_id: "1",
//   },
//   {
//     title: "Experience Cool Breeze",
//     thumb_url: CartItemThumb,
//     creator_handle: "@SonicSketchEntertainment",
//     category: "Volumetric",
//     price: "24.00",
//     id: "1",
//     creator_id: "1",
//   },
// ];

const Cart = () => {
  const { data, isLoading, refetch } = useGetCartItemByTokenQuery();
  const {
    data: starData,
    isLoading: starLoading,
    refetch: starRefetch,
  } = useGetStarCartItemByTokenQuery();
  const [DeleteCartItemQuery, { isLoading: deleteCartLoader }] =
    useDeleteCartItemByTokenMutation();
  const [cartData, setCartData] = useState([]);
  const [IsalertMsg, setIsAlertMsg] = useState(false);

  const [loadingIndexes, setLoadingIndexes] = useState([]);
  const [openWalletAddressPopup, setOpenWalletAddressPopup] = useState(false);
  const addressLists = useGetUserWalletListQuery();
  const [walletAddressList, setWalletAddressList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  // const goToWalletAddress = () => {
  //   navigate("/wallet");
  // };

  // Open add wallet address model
  const openWalletAddress = () => {
    setShowAlert(false);
    setShowConfirmPopup(true);
  };

   // Open add wallet address list model
  const walletAdded = (success) => {
    if (success) {
      setOpenWalletAddressPopup(true);
    }
  };

  //Close Add wallet address box
  const onCloseConfirmPopup = () => {
    setShowConfirmPopup(false);
    // setOpenWalletAddressPopup(true);
  };

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setAlertSuccess(true);
  };

  const onWalletPopupClose = () => {
    setOpenWalletAddressPopup(false);
  };

  const onActionNextBtnClick = (walletId) => {
    // console.log("walletId after click next buton", walletId);
    checkoutCartStar(walletId);
  };

  useEffect(() => {
    refetch();
    starRefetch();
    setCartData(data?.response?.data);
  }, [data, starData]);

  // Calculate Total Sum of Cart Items
  const CartSumOfItemPrice = () => {
    return data?.response?.data
      .map((item) => item?.price)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const CartStarSumOfItemPrice = () => {
    return starData?.response?.data
      .map((item) => item?.price)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  // Delete Cart Items
  const deleteCartItem = (cartItemId, index) => {
    setLoadingIndexes((prev) => [...prev, index]);
    DeleteCartItemQuery({ id: cartItemId })
      .then((res) => {
        setIsAlertMsg(true);
        refetch();
        starRefetch();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigate = useNavigate();

  const checkoutCartUSD = () => {
    let cartCheckoutData = data?.response?.data.slice();
    cartCheckoutData = cartCheckoutData.map((item) => {
      return {
        amount: item.price,
        productId: item.itemId,
        productType: item.itemCategory,
        name: item.itemName,
        themeId: item.itemsThemeId,
      };
    });

    sessionStorage.setItem(
      "cartCheckoutData",
      JSON.stringify(cartCheckoutData)
    );
    navigate("/checkout?isCart=true");
  };

  useEffect(() => {
    setWalletAddressList(addressLists?.data?.response?.data?.web3Wallets);
  }, [addressLists]);

  const handleFstrCheckout = () => {
    if (addressLists?.data?.response?.data?.web3Wallets.length > 0) {
      // setWalletAddressList(addressLists?.data?.response?.data?.web3Wallets);
      setOpenWalletAddressPopup(true);
      setShowAlert(false);
    } else {
      // console.log("No wallet address list found");
      setAlertSuccess(false);
      setAlertMessage(
        "You haven’t connected any wallet yet. Please add a Web3 wallet to proceed with your FSTR payment. Without a wallet, you won’t be able to complete the purchase."
      );
      setShowAlert(true);
      setOpenWalletAddressPopup(false);
    }
  };

  const checkoutCartStar = (walletId) => {
    let cartCheckoutData = starData?.response?.data.slice();
    cartCheckoutData = cartCheckoutData.map((item) => {
      return {
        amount: item.price,
        productId: item.itemId,
        productType: item.itemCategory,
        name: item.itemName,
        themeId: item.itemsThemeId,
      };
    });

    sessionStorage.setItem(
      "cartCheckoutDataCrypto",
      JSON.stringify(cartCheckoutData)
    );
    const amount = cartCheckoutData.reduce((acc, current) => {
      return acc + current.amount;
    }, 0);
    navigate(`/initiatepayment?isCart=true&amount=${amount}&walletId=${walletId}`);
  };

  return (
    <>
      <div className="__my_transaction__container__main mb-[100px] container mx-auto px-[100px] py-[60px] max-[768px]:px-[15px] max-[768px]:py-[40px] relative font-body min-h-[100vh]">
        <ShadowFrame className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
        <ShadowFrame className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
        {/* <div className="flex items-center gap-4">
          <img src={CartUsdIcon} className="h-[40px] w-[40px] inline" alt="" />
          <h1 className="text-[24px] font-heading ">USD Purchases</h1>
        </div> */}

        {/* USD CART */}

        {isLoading && (
          <div className="text-center pt-[10%]">
            <Loader />
          </div>
        )}
        {!isLoading &&
          data?.response?.statusEnum != 1 &&
          (data.response?.message || (
            <p className="font-bold text-white/50">Something went wrong</p>
          ))}
        {!isLoading &&
          data?.response?.statusEnum == 1 &&
          data?.response?.data?.length == 0 &&
          starData?.response?.data?.length == 0 && (
            <div className="__EMPTY_CART_CONTAINER__ mt-[130px] w-full md:max-w-[50%] mx-auto">
              <h4 className="text-[20px]  font-bold leading-[30px] text-center">
                Cart is empty!
              </h4>
              <p className="mt-[35px] text-[16px] text-[#7a7a7a] font-bold leading-[24px] text-center">
                🌟 All purchases are coming soon! We're gearing up for exciting
                releases, so stay tuned for amazing content. 🚀
              </p>
              <div className="mt-[60px] flex justify-center items-center">
                <Link
                  to="/marketplace"
                  className="border-[1.5px] border-sec text-sm  rounded-lg max-w-[250px] hover:bg-sec hover:text-dark font-bold text-center  py-3 px-4"
                >
                  Explore Marketplace
                </Link>
              </div>
            </div>
          )}

        {!isLoading &&
          data?.response?.statusEnum == 1 &&
          data?.response?.data?.length > 0 && (
            <div>
              <div className="flex items-center gap-4">
                <img
                  src={CartUsdIcon}
                  className="h-[40px] w-[40px] inline"
                  alt=""
                />
                <h1 className="text-[24px] font-heading ">USD Purchases</h1>
              </div>
              <table className="__CART_ITEMS_CONTAINER__ mt-[34px] mx-auto w-full max-md:table-fixed ">
                <thead>
                  <tr className="text-[8px] sm:text-[10px] md:text-[12px] text-white/70 font-bold border-b border-white/50">
                    <th className="text-start pb-[12px]">Thumbnail & name</th>
                    <th className="text-start pb-[12px]">Category</th>
                    <th className="text-start pb-[12px]">Price</th>
                    <th className="text-start pb-[12px]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.response?.data
                    .slice(0)
                    ?.reverse()
                    ?.map((item, index) => {
                      return (
                        <tr
                          key={item?._id + item?.userId}
                          className="border-b border-white/20 "
                        >
                          <td className="py-[10px]">
                            <div className="__THUMB_NAME_CONTAINER__ md:flex items-center gap-2 md:gap-[20px]">
                              <div className="__THUMB_CONTAINER_ hidden sm:block w-[100px] h-[85px] md:w-[162px] md:h-[85px] bg-black">
                                <img
                                  src={item?.thumbnail}
                                  className="w-full h-full object-contain"
                                  alt="asset"
                                />
                              </div>
                              <div className="__TITLE_CREAT_HANDLE_CONTAINER__">
                                <p className="text-[12px] md:text-[14px] font-bold max-md:max-w-[90%]  text-ellipsis whitespace-nowrap overflow-hidden">
                                  {item.itemName}
                                </p>
                                {item?.itemChannelHandle && (
                                  <p className="text-[#7a7a7a] text-[10px] md:text-[12px] font-bold max-md:max-w-[90%] text-ellipsis whitespace-nowrap overflow-hidden">
                                    <Link
                                      to={`/marketplace/entertainment/channels/${item.creator_id}`}
                                    >
                                      {item?.itemCategory}
                                    </Link>
                                  </p>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className="text-[#7a7a7a] text-[12px] md:text-[14px] font-bold pl-1 capitalize">
                            {item?.itemCategory?.split("-")?.join(" ")}
                          </td>

                          <td>
                            <div className="__COIN_PRICE_CONTAINER__ flex items-center gap-2 pl-1">
                              {/* <img
                            src={CoinIcon}
                            className="hidden md:block w-[20px] h-[20px]"
                            alt="coin"
                          /> */}
                              <span className="text-[12px] md:text-[14px] font-bold whitespace-nowrap">
                                {item?.price} USD
                              </span>
                            </div>
                          </td>

                          <td className="xl:w-[150px]">
                            <div className="__BUY_DELETE_CONTAINER__ flex items-center pl-3 justify-start">
                              {/* <button className="btn-outline py-[4px] px-[6px] md:py-[7px] md:px-[12px] text-[8px] sm:text-[12px] md:text-[14px] border border-[#fbbc5e] rounded-[20px]  font-medium">
                            Buy Now
                          </button> */}

                              {deleteCartLoader &&
                              loadingIndexes.includes(index) ? (
                                <img
                                  src={MiniLoader}
                                  className="w-[25px] h-[25px]"
                                />
                              ) : (
                                <span
                                  onClick={() =>
                                    deleteCartItem(item?._id, index)
                                  }
                                  className="__delete__handle__item cursor-pointer"
                                >
                                  <svg
                                    className="w-[16px] h-[16px] md:w-[24px] md:h-[24px]"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                      fill="white"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div className="__BUY_ALL_CONTAINER__ mt-[24px] max-md:mx-auto md:ml-auto w-full max-w-[400px] flex flex-col gap-5">
                <div className="flex items-center justify-between">
                  <span className="text-white/50 font-semibold text-[16px]">
                    Total Items:
                  </span>
                  <span className="text-white font-medium text-[16px]">
                    {data?.response?.data?.length} Items
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white/50 font-semibold text-[16px]">
                    Total Amount:
                  </span>
                  <span className="text-white font-bold text-[16px]">
                    {CartSumOfItemPrice()} USD
                  </span>
                </div>

                <button
                  className=" btn-gradient py-[8px] px-[24px] lg:py-[12px] lg:px-[48px] rounded-[8px] text-black text-[12px] lg:text-[16px] font-bold"
                  onClick={checkoutCartUSD}
                >
                  Checkout
                </button>
              </div>
            </div>
          )}
        {!starLoading &&
          starData?.response?.statusEnum == 1 &&
          starData?.response?.data?.length > 0 && (
            <div>
              <div className="flex items-center gap-4 mt-[30px]">
                <img
                  src={CartFstrIcon}
                  className="h-[40px] w-[40px] inline"
                  alt=""
                />
                <h1 className="text-[24px] font-heading ">FSTR Purchases</h1>
              </div>
              <table className="__CART_ITEMS_CONTAINER__ mt-[34px] mx-auto w-full max-md:table-fixed ">
                <thead>
                  <tr className="text-[8px] sm:text-[10px] md:text-[12px] text-white/70 font-bold border-b border-white/50">
                    <th className="text-start pb-[12px]">Items </th>
                    <th className="text-start pb-[12px]">Category</th>
                    <th className="text-start pb-[12px]">Price</th>
                    <th className="text-start pb-[12px]">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {starData?.response?.data
                    .slice(0)
                    ?.reverse()
                    ?.map((item, index) => {
                      return (
                        <tr
                          key={item?._id + item?.userId}
                          className="border-b border-white/20 "
                        >
                          <td className="py-[10px]">
                            <div className="__THUMB_NAME_CONTAINER__ md:flex items-center gap-2 md:gap-[20px]">
                              <div className="__THUMB_CONTAINER_ hidden sm:block w-[100px] h-[85px] md:w-[162px] md:h-[85px] bg-black">
                                <img
                                  src={item?.thumbnail}
                                  className="w-full h-full object-contain"
                                  alt="asset"
                                />
                              </div>
                              <div className="__TITLE_CREAT_HANDLE_CONTAINER__">
                                <p className="text-[12px] md:text-[14px] font-bold max-md:max-w-[90%]  text-ellipsis whitespace-nowrap overflow-hidden">
                                  {item.itemName}
                                </p>
                                {item?.itemChannelHandle && (
                                  <p className="text-[#7a7a7a] text-[10px] md:text-[12px] font-bold max-md:max-w-[90%] text-ellipsis whitespace-nowrap overflow-hidden">
                                    <Link
                                      to={`/marketplace/entertainment/channels/${item.creator_id}`}
                                    >
                                      {item?.itemCategory}
                                    </Link>
                                  </p>
                                )}
                              </div>
                            </div>
                          </td>

                          <td className="text-[#7a7a7a] text-[12px] md:text-[14px] font-bold pl-1 capitalize">
                            {item?.itemCategory?.split("-")?.join(" ")}
                          </td>

                          <td>
                            <div className="__COIN_PRICE_CONTAINER__ flex items-center gap-2 pl-1">
                              {/* <img
                            src={CoinIcon}
                            className="hidden md:block w-[20px] h-[20px]"
                            alt="coin"
                          /> */}
                              <span className="text-[12px] md:text-[14px] font-bold whitespace-nowrap">
                                {item?.price} FSTR
                              </span>
                            </div>
                          </td>

                          <td className="xl:w-[150px]">
                            <div className="__BUY_DELETE_CONTAINER__ flex items-center pl-3 justify-start">
                              {/* <button className="btn-outline py-[4px] px-[6px] md:py-[7px] md:px-[12px] text-[8px] sm:text-[12px] md:text-[14px] border border-[#fbbc5e] rounded-[20px]  font-medium">
                            Buy Now
                          </button> */}

                              {deleteCartLoader &&
                              loadingIndexes.includes(index) ? (
                                <img
                                  src={MiniLoader}
                                  className="w-[25px] h-[25px]"
                                />
                              ) : (
                                <span
                                  onClick={() =>
                                    deleteCartItem(item?._id, index)
                                  }
                                  className="__delete__handle__item cursor-pointer"
                                >
                                  <svg
                                    className="w-[16px] h-[16px] md:w-[24px] md:h-[24px]"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M7 21C6.45 21 5.979 20.804 5.587 20.412C5.195 20.02 4.99933 19.5493 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.804 20.021 18.412 20.413C18.02 20.805 17.5493 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                      fill="white"
                                    />
                                  </svg>
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div className="__BUY_ALL_CONTAINER__ mt-[24px] max-md:mx-auto md:ml-auto w-full max-w-[400px] flex flex-col gap-5">
                <div className="flex items-center justify-between">
                  <span className="text-white/50 font-semibold text-[16px]">
                    Total Items:
                  </span>
                  <span className="text-white font-medium text-[16px]">
                    {starData?.response?.data?.length} Items
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="text-white/50 font-semibold text-[16px]">
                    Total Amount:
                  </span>
                  <span className="text-white font-bold text-[16px]">
                    {CartStarSumOfItemPrice()} FSTR
                  </span>
                </div>

                <button
                  className=" btn-gradient py-[8px] px-[24px] lg:py-[12px] lg:px-[48px] rounded-[8px] text-black text-[12px] lg:text-[16px] font-bold"
                  onClick={handleFstrCheckout}
                >
                  Checkout
                </button>
              </div>
            </div>
          )}
      </div>

      {openWalletAddressPopup && !showAlert && (
        <WalletAddressModel
          addressList={walletAddressList}
          onCancel={onWalletPopupClose}
          onNext={onActionNextBtnClick}
          btnText="Next"
        />
      )}

      {showConfirmPopup && !showAlert && (
        <AddWalletAddress
          walletAdd={walletAdded}
          onClose={onCloseConfirmPopup}
        />
      )}

      {showAlert && !openWalletAddressPopup && (
        <Alert
          open={showAlert}
          message={alertMessage}
          showCloseIcon={true}
          isSuccess={alertSuccess}
          canClose={false}
          onClose={openWalletAddress}
          onClickCloseIcon={onAlertClose}
          buttonText={"Add Wallet"}
        />
      )}

      {IsalertMsg && (
        <Alert
          open={true}
          onClose={() => {
            setIsAlertMsg(!IsalertMsg);
          }}
          heading="Item Removed"
          message=""
          isSuccess={true}
        />
      )}
    </>
  );
};

export default Cart;
