"use client";

import { useEffect, useRef, useState } from "react";
import { Paperclip, Send } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import webRequestHandler from "constant/api/apiInstance";
import { EndpointsSlug } from "constant/api/Endpoints";
import { TicketDetails } from "components/helpDesk/ticketDetails/page";
import { ChatHistory } from "components/helpDesk/chatHistory/page";
import ShadowFrame from "components/shadow-frame";
import { removeFromS3 } from "utils/s3";
import ErrorAlert from "utils/errorAlert";
import { uploadToS3 } from "utils/s3";
import Loader from "components/Loader/Loader";
// import { Button } from "@/components/ui/button";
// import { Textarea } from "@/components/ui/textarea";

const timestamp = Date.now();

export default function Details() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [feedbackDetail, setFeedbackDetail] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const instance = webRequestHandler();
  const [isTextareaPristine, setIsTextareaPristine] = useState(true);
  const screenshotInputRef = useRef(null);
  // const [screenshotPristine, setScreenshotPristine] = useState(true);
  const [screenshotLoading, setScreenshotLoading] = useState(false);
  const [screenshotPreview, setScreenshotPreview] = useState("");
  const [screenshotFileName, setScreenshotFileName] = useState("");
  const [screenshot, setScreenshot] = useState(null);

  const scrollToBottom = () => {
    // Select the chat container div
    const chatContainer = document.getElementsByClassName(
      "custom-chat-container"
    );
    // Scroll to the bottom
    if (chatContainer.length > 0) {
      chatContainer[0].scrollTop = chatContainer[0]?.scrollHeight;
    }
  };

  function scrollToAndClickTextarea() {
    // Get the textarea element by its ID, class, or any other selector
    const textarea = document.querySelector("#myTextarea"); // Use an appropriate selector

    if (textarea) {
      // Scroll to the textarea element
      textarea.scrollIntoView({ behavior: "smooth", block: "center" });

      // Simulate a click on the textarea
      textarea.click();

      // Optionally focus the textarea (useful for user interaction)
      textarea.focus();
    } else {
      console.error("Textarea element not found!");
    }
  }

  const fetchMessages = async (parsedData) => {
    // Simulate fetching data from an API
    setLoading(true);
    setMessages([]);
    try {
      const r = await instance.get(
        `${EndpointsSlug.GET_SINGLE_FEEDBACK(parsedData?.ticketId)}`
      );
      if (r.response.data.messages) {
        const newData = r.response.data.messages;
        setFeedbackDetail((prevDetail) => ({
          ...prevDetail,
          status: r.response.data.status,
        }));
        if (newData.length > 0) {
          setMessages(newData);
        } else {
          // Taking Care of the OLD Tickets
          const message = {
            id: String(1),
            sender: "user",
            content: parsedData?.description,
            timestamp: new Date(parsedData?.updatedAt).toISOString(),
          };
          setMessages([message]);
        }
      }
    } catch (error) {
      console.error("Error while fetching admin feedback messages", error);
    } finally {
      setLoading(false);
    }
  };

  const changeTicketStatus = async (status) => {
    // Simulate fetching data from an API
    if (feedbackDetail?.status === 0) {
      // setMessages([]);
      try {
        const r = await instance.post(EndpointsSlug.CHANGE_STATUS_FEEDBACK, {
          ticketId: feedbackDetail?.ticketId,
          // userId: feedbackDetail?.userData[0]?._id,
          // status: status,
        });
        if (r.response.statusEnum === 1) {
          setFeedbackDetail({ ...feedbackDetail, status: 1 });
          setIsTextareaPristine(true)
          
        }
      } catch (error) {
        console.error("Error while fetching admin feedback list", error);
      } finally {
        setLoading(false);
      }
    } else {
      setIsTextareaPristine(false);
      scrollToAndClickTextarea();
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    try {
      const message = {
        id: String(messages.length + 1),
        sender: "user",
        content: newMessage,
        timestamp: new Date().toISOString(),
        screenshots: screenshot?.url,
      };

      setNewMessage("");
      setScreenshot(null);
      setScreenshotPreview(null);
      setScreenshotFileName(null);

      console.log("messStarted", message);

      const r = await instance.post(EndpointsSlug.SEND_FEEDBACK, {
        ticketId: feedbackDetail?.ticketId,
        content: message.content,
        screenshots: screenshot?.url,
      });
      if (r.response.statusEnum === 1) {
        setMessages([...messages, message]);
        setFeedbackDetail({ ...feedbackDetail, status: 0 });
      }
    } catch (error) {
      console.error("Error while fetching admin feedback list", error);
    } finally {
      // setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const removeScreenshot = () => {
    removeFromS3(
      `${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/help-center`,
      screenshot.fileName
    );
    setScreenshot(null);
    setScreenshotPreview(null);
    setScreenshotFileName(null);
  };

  const onScreenshotInputChange = (ev) => {
    setScreenshotLoading(true);
    // setScreenshotPristine(false);
    const items = ev.target.files;
    if (items.length > 0) {
      const file = items[0];
      const type = file.type;
      // dropped something other than an image
      if (
        type !== "image/png" &&
        type !== "image/jpg" &&
        type !== "image/jpeg"
      ) {
        ErrorAlert("Please upload image only.");
        return;
      }

      // to show thumbnail preview
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        // Image Validation
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          // Delete if there is any thumbnail
          if (screenshot?.fileName) removeScreenshot();

          // Upload to s3
          uploadToS3(
            file,
            `${process.env.REACT_APP_AWS_S3_BUCKET_NAME}/help-center`,
            timestamp + "**" + file.name
          )
            .then((s3upload) => {
              setScreenshotPreview(e.target.result);
              setScreenshotFileName(file.name);
              setScreenshot({
                url: s3upload.Location,
                fileName: timestamp + "**" + file.name,
              });
            })
            .catch((error) => {
              console.log("Error while uploading bug screenshot", error);
            })
            .finally(() => {
              setScreenshotLoading(false);
            });
        };
      };
    }
  };

  useEffect(() => {
    const dataParam = searchParams.get("data");

    if (dataParam) {
      try {
        const parsedData = JSON.parse(decodeURIComponent(dataParam));
        setFeedbackDetail(parsedData); // Update feedbackDetail state
        fetchMessages(parsedData); // Call fetchMessages with the parsed data
      } catch (error) {
        console.error("Failed to parse dataParam:", error);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // useEffect(() => {
  //   if (feedbackDetail)
  // }, [feedbackDetail]);

  return (
    <div className="__my_transaction__container__main mb-[100px] container mx-auto px-[15px] py-[50px] max-[768px]:px-[15px] max-[768px]:py-[40px] max-[1280px]:px-[15px] relative">
      <ShadowFrame className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <ShadowFrame className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <div className="min-h-screen text-white ">
        <button
          onClick={() => navigate("/help-center/ticket-list")}
          className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-[#000000d7] rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3 z-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M12.5 15L7.5 10L12.5 5"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Back</span>
        </button>
        <div className="m-auto">
          <TicketDetails
            feedbackDetail={feedbackDetail}
            onReopen={() => changeTicketStatus(0)}
            onClose={() => changeTicketStatus(1)}
          />
          <div className=" my-5 max-w-[80%] mx-auto">
            <p className="text-white font-semibold mb-2 mt-[-40px]">
              Chat History
            </p>
            <div className="border-[0.5px] rounded-lg border-[#636362]">
              <ChatHistory
                title={feedbackDetail?.title}
                messages={messages}
                isLoading={loading}
              />

              <div className="p-0 bg-zinc-800/50">
                <div className="flex gap-2">
                  <div className="flex  !w-full items-center border-none p-0 rounded-b-lg">
                    <div className={`bg-[#32323149] h-full  border-t ${screenshotPreview ||
                          (isTextareaPristine && feedbackDetail?.status)?"border-t-[#868789]":"border-t-gray-200"} `}>
                      {screenshotLoading?
                      <div className="px-3 mt-2 ml-1 rounded-full py-3">
                        <Loader />
                        </div>
                      :<button
                        onClick={() => {
                          // setScreenshotPristine(false);
                          screenshotInputRef.current.click();
                        }}
                        className="px-3 mt-2 ml-1 rounded-full py-3"
                        disabled={
                          screenshotPreview ||
                          (isTextareaPristine && feedbackDetail?.status)
                        }
                      >
                        <Paperclip
                          className={`h-5 w-5 ${
                            screenshotPreview ||
                            (isTextareaPristine && feedbackDetail?.status)
                              ? "text-[#5e5d5d]"
                              : "text-[#FBBC5E]"
                          }`}
                        />
                      </button>}
                      <input
                        ref={screenshotInputRef}
                        className="absolute opacity-0 pointer-events-none"
                        type="file"
                        name=""
                        id=""
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={onScreenshotInputChange}
                        // value={(e) => e.target.name}
                      />
                    </div>
                    <div className="relative flex-grow">
                      <textarea
                        placeholder="Type the message ..."
                        id="myTextarea"
                        data-test-id="myTextarea"
                        className="bg-[#32323186] placeholder-gray-200 text-white text-base font-normal px-3 py-4 rounded-b-lg border-t border-t-gray-200 focus:outline-none disabled:opacity-50 resize-none w-full h-full"
                        disabled={isTextareaPristine && feedbackDetail?.status}
                        onKeyDown={handleKeyDown}
                        rows={"3"}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                      />

                      {/* Image Preview */}
                      {screenshotPreview && (
                        <div className="absolute bottom-2 right-2 flex items-center gap-2 bg-[#3f3f3f] p-2 rounded-md shadow-lg border border-[#707070]">
                          <div
                            className="w-[40px] h-[40px] bg-center bg-cover bg-no-repeat rounded"
                            style={{
                              backgroundImage: `url(${screenshotPreview})`,
                            }}
                          ></div>
                          <div>
                            <p className="text-[10px] text-[#7A7A7A] truncate">
                              {screenshotFileName}
                            </p>
                            <div className="flex gap-2">
                              <button
                                className="text-[10px] text-blue-500 font-bold"
                                type="button"
                                onClick={() =>
                                  screenshotInputRef.current.click()
                                }
                              >
                                Replace
                              </button>
                              <button
                                className="text-[10px] text-red-500 font-bold"
                                type="button"
                                onClick={removeScreenshot}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={`bg-[#32323149] h-full  border-t ${screenshotPreview ||
                          (isTextareaPristine && feedbackDetail?.status)?"border-t-[#868789]":"border-t-gray-200"} `}>
                      <button
                        onClick={handleSendMessage}
                        className="px-3 bg-[#FBBC5E] disabled:bg-[#5e5d5d] mt-5 mr-1 rounded-full py-3"
                        disabled={isTextareaPristine && feedbackDetail?.status}
                      >
                        <Send className="h-5 w-5 text-black" />
                      </button>
                    </div>
                  </div>
                  {/* <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type response here..."
                className="min-h-[50px] border-[#636362] text-white"
              /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
