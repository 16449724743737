import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Explore from "../Explore";
import ChannelItem from "../ChannelItem";
import ConfirmCancel from "components/popup/subscriptions/ConfirmCancel";
import Cancelled from "components/popup/subscriptions/Cancelled";
import TransactionsTable from "components/popup/subscriptions/TransactionsTable";

import {
  useGetSubscriptionsListQuery,
  usePatchCancelSubscriptionMutation,
} from "store/slicer/MyAssets/Subscriptions";

import dateFormat from "dateformat";
import Loader from "components/Loader/Loader";
import CancelledError from "components/popup/subscriptions/CancelledError";

const ActiveSubscriptions = () => {
  const navigate = useNavigate();
  let { data, isFetching, isSuccess, isError, refetch } =
    useGetSubscriptionsListQuery({
      page: 1,
      limit: 9999,
    });
  const [cancelSubscription, cancelSubscriptionInfo] =
    usePatchCancelSubscriptionMutation();

  if (!isFetching && data?.response?.statusEnum == 1) {
    data = data?.response?.data?.subscribedUsers;
    data = data?.filter((item) => {
      return (
        item?.status == "active" ||
        item?.status == "onhold" ||
        item?.status == "failed"
      );
    });

    data = data.map((item) => {
      return {
        id: item?.subscription?._id,
        channelThumb: item?.channelThumbnail,
        channelName: item?.channelName,
        channelHandle: item?.channelHandle,
        channelId: item?.subscription?.channelId,
        subscriptionStatus: item?.subscription?.subscriptionType,
        paymentMethod: item?.currency?.toLowerCase() == "usd" ? "usd" : "fstr",
        price: item?.price,
        lastPaymentStatus: item?.paymentStatus,
        renewalDate: dateFormat(
          new Date(item?.renewalDate || ""),
          "dd/mm/yyyy"
        ),
        dueDate: dateFormat(
          new Date(item?.renewalDate != undefined ? item?.renewalDate : 0),
          "mm/dd/yyyy"
        ),
        expiredDate: dateFormat(
          new Date(item?.expiredDate != undefined ? item?.expiredDate : 0),
          "dd/mm/yyyy"
        ),
        cancelled:
          item?.subscription?.subscriptionType == "cancelled" ||
          item?.subscription?.subscriptionType == "expired",
      };
    });
  } else {
    data = [];
    // isError = true;
  }

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showCancelledPopup, setShowCancelledPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showTransactionsPopup, setShowTransactionsPopup] = useState(false);
  const [activeChannelId, setActiveChannelId] = useState(null);

  const onCloseConfirmPopup = () => {
    setShowConfirmPopup(false);
  };

  const onCloseCancelledPopup = () => {
    refetch();
    setShowCancelledPopup(false);
    setShowErrorPopup(false)
  };

  const onCloseTransactionPopup = () => {
    setShowTransactionsPopup(false);
  };

  const onSeeAllTransactions = (id) => {
    setActiveChannelId(id);
    setShowTransactionsPopup(true);
  };

  const onCancelSubscription = (channelId) => {
    setActiveChannelId(channelId);
    setShowConfirmPopup(true);
  };

  const onPaySubscription = (id) => {
    console.log("pay for subscription ", id);
    navigate(`/initiatepayment?isSubscription=true&channelId=${id}`);
  };

  const onConfirmCancel = async () => {
    const cancelSubscriptionDetails = await cancelSubscription({ channelId: activeChannelId });
    if(cancelSubscriptionDetails.error) {
      onCloseConfirmPopup();
      setShowErrorPopup(true)
    } else {
      onCloseConfirmPopup();
      setShowCancelledPopup(true);
    }
  };

  if (isFetching) {
    return (
      <div className="min-h-screen grid place-content-center">
        <Loader />
      </div>
    );
  }

  // if (!isFetching && isError) {
  //   return (
  //     <div className="min-h-screen grid place-content-center">
  //       <p className="text-white/70 text-[16px] leading-[21px]">
  //         Something went wrong
  //       </p>
  //     </div>
  //   );
  // }

  if (!isFetching && data?.length === 0) {
    return (
      <div className="min-h-screen grid place-content-center">
        <div className="mt-20 w-full lg:max-w-[500px] mx-auto">
          <Explore />
        </div>
      </div>
    );
  }

  return (
    <>
      {showCancelledPopup && (
        <Cancelled
          open={showCancelledPopup}
          canClose={true}
          onClose={onCloseCancelledPopup}
        />
      )}
       {showErrorPopup && (
        <CancelledError
          open={showErrorPopup}
          canClose={true}
          onClose={onCloseCancelledPopup}
        />
      )}
      {showConfirmPopup && (
        <ConfirmCancel
          open={showConfirmPopup}
          onClose={onCloseConfirmPopup}
          cancelBtnHandler={onConfirmCancel}
          isLoading={cancelSubscriptionInfo.isLoading}
        />
      )}

      {showTransactionsPopup && (
        <TransactionsTable
          channelId={activeChannelId}
          open={showTransactionsPopup}
          onClose={onCloseTransactionPopup}
        />
      )}
      <div className="__ITEMS_MAIN_CONTAINER__ mt-3">
        <div className="flex justify-start items-center py-4 px-2 [&>p]:w-1/4 bg-[#2E2E2E]">
          <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium">
            Channel Subscribed
          </p>
          <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium">
            Subscription Details
          </p>
          <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium">
            Last Auto-debit Status
          </p>
          <p className="font-Inter text-white/60 text-[10px] md:text-[12px] md:leading-[14.52px] font-medium text-center">
            Actions
          </p>
        </div>
        {data.map((item, index) => (
          <ChannelItem
            key={index}
            {...item}
            seeAllTransactionsHandler={onSeeAllTransactions}
            cancelBtnHandler={onCancelSubscription}
            payRenewBtnHandler={onPaySubscription}
          />
        ))}
      </div>
    </>
  );
};

export default ActiveSubscriptions;
