import React, { useState, useEffect } from "react";
import ShadowFrame from "components/shadow-frame";
import MyOrderItemModal from "components/Modal/MyOrderItem/MyOrderItemModal";
import OrderCart from "../../../assets/images/order-cart.png";
import Loader from "components/Loader/Loader";
import { useGetTicketListQuery } from "store/slicer/HelpCenter";
import { useNavigate } from "react-router-dom";

const ListTickets = () => {
  const [isInviteModalStatus, setInviteModal] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching, refetch } = useGetTicketListQuery({
    page: page,
    limit: 100000,
  });
  const navigate = useNavigate();
  const handleInviteModal = () => {
    setInviteModal(!isInviteModalStatus);
  };

  const onSeeDetailsHandler = (id) => {
    let filterData = orderData.filter((item) => item._id === id);
    setOrderData(filterData[0]);
    // Serialize the feedbackDetail object
    const serializedData = encodeURIComponent(JSON.stringify(filterData[0]));

    // Redirect with serialized object
    navigate(`/help-center/ticket-list/details?data=${serializedData}`);
    // setShowFeedbackModal(true);
  };

  useEffect(() => {
    if (page === 1) {
      if (data?.response?.statusEnum === 1) {
        setOrderData(data.response.data);
      }
    } else {
      if (data?.response?.data?.gateways?.length) {
        setOrderData((prevData) => [
          ...prevData,
          ...data.response.data.gateways,
        ]);
      }
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [page]);
  return (
    <div className="__my_transaction__container__main mb-[100px] container mx-auto px-[15px] py-[50px] max-[768px]:px-[15px] max-[768px]:py-[40px] max-[1280px]:px-[15px] relative">
      <ShadowFrame className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <ShadowFrame className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <button
        onClick={() => navigate("/help-center")}
        className="inline-flex gap-2 text-white font-semibold text-xs md:text-sm leading-normal bg-white/[0.10] hover:bg-white/[0.90] hover:text-[#000000d7] rounded-3xl py-1.5 px-1.5 pr-3 items-center  top-3 left-3 z-20"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M12.5 15L7.5 10L12.5 5"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>Back</span>
      </button>
      <div className="space-y-4 p-6 rounded-t-lg">
        <div className="__trasaction__header__main relative">
          <h4 className="font-heading text-[24px] max-[768px]:text-[18px]">
            Help Center Tickets
          </h4>
        </div>
        <div className="__transaction__content__main h-[90%] relative ">
          {!isLoading && orderData && orderData.length > 0 && (
            <div className="__transaction__table__content_main w-[100%] mt-[30px] overflow-x-scroll">
              <table className="table-auto w-full">
                <thead>
                  <tr className="[&>th]:text-[#FFFFFF99] [&>th]:font-normal [&>th]:text-[12px] [&>th]:text-left [&>th]:px-[10px] [&>th]:py-[10px] [&>th]:border-b [&>th]:border-b-[#8d8a8a80]">
                    <th>Ticket ID</th>
                    <th>Title</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData.map((_tr, index) => {
                    return (
                      <tr
                        key={index}
                        className="[&>td]:px-[10px] [&>td]:py-[18px] [&>td]:text-[14px] [&>td]:border-b [&>td]:border-b-[#5A5A5A80]"
                      >
                        <td>{_tr?.ticketId}</td>
                        <td>{_tr?.title}</td>
                        <td>{new Date(_tr?.createdAt).toLocaleString()}</td>
                        <td>
                          {_tr?.status === 1 ? (
                            <span className="text-[#56AA6D] font-medium">
                              Resolved
                            </span>
                          ) : (
                            <span className="text-[#FF6458] font-medium">
                              Unresolved
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            className="border border-[#a5a3a3] hover:border-[#FBBC5E] bg-gradient-to-r hover:from-[#FBBC5E] hover:to-[#F3A151] hover:text-[#000] hover:font-bold  w-[98px] br-[8px] text-[12px] font-bold font-body rounded-[6px]"
                            onClick={() => {
                              onSeeDetailsHandler(_tr._id);
                            }}
                          >
                            Manage
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {!isLoading && orderData.length === 0 && (
            <div className="__transaction__empty__container flex flex-col mt-[200px] items-center h-[100%] max-[768px]:justify-start max-[768px]:pt-[30px] max-[1280px]:pt-[30px] max-[1280px]:justify-start gap-3">
              <span className="text-[#FFFFFF99] text-[16px] font-medium font-body max-[768px]:text-[14px]">
                No Tickets create yet!
              </span>
            </div>
          )}
          {isFetching && (
            <div className="__default_container mt-[20px] min-h-[300px] flex flex-col justify-center items-center">
              <div className="__no_friend_container flex flex-col">
                <h4 className="text-[16px] text-[#7A7A7A] font-body font-medium">
                  <Loader />
                </h4>
              </div>
            </div>
          )}
          {data?.response?.data?.gateways?.length === 10 && (
            <div className="flex justify-center mt-[30px]">
              <button
                onClick={() => setPage(page + 1)}
                className="inline-flex border cursor-pointer text-sm md:text-base leading-none rounded-2xl border-sec btn-outline hover:text-black hover:font-semibold px-6 py-3 md:py-4 md:px-8 mt-6 font-medium"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListTickets;
