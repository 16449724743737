import { Paperclip } from "lucide-react";

export function Message({ message }) {
  const isAdmin = message.sender === "admin";
  const extractAfterDoubleAsterisk = (url) => {
    try {
      // Decode the URL
      const decodedUrl = decodeURIComponent(url);

      // Extract the part after "**"
      if (decodedUrl.includes("**")) {
        return decodedUrl.split("**")[1];
      } else {
        throw new Error("Double asterisk '**' not found in the URL");
      }
    } catch (error) {
      console.error(error.message);
      return url; // Return the whole URL if an error occurs
    }
  };
  return (
    <div
      className={`flex flex-col gap-2 ${!isAdmin ? "items-end" : "items-start"}`}
    >
      <div className="flex items-center font-bold gap-2 text-sm">
        <span className={isAdmin ? "text-[#FFBD62]" : "text-[#DB62C5]"}>
          {isAdmin ? "Admin Response" : "User query"}
        </span>
        <span className="text-zinc-400">
          {new Date(message.timestamp).toLocaleString()}
        </span>
      </div>

      <div
        className={`max-w-[80%] rounded-lg p-4 ${
          !isAdmin ? "bg-[#283941]" : "bg-[#3A3A3A]"
        }`}
      >
        <p className="text-white">{message.content}</p>
        {message.screenshots && (
          <div className="flex items-center gap-2 mt-2 p-2 bg-[#253a44] rounded text-sm text-zinc-300">
            <Paperclip className="w-4 h-4" />
            <div className="w-[250px] max-w-full">
              <div
                className="__UPLOADED_THUMB__ h-[160px] bg-[#3f3f3f] bg-no-repeat bg-center bg-contain"
                style={{
                  backgroundImage: `url(${message.screenshots})`,
                }}
              ></div>
              <p className="__UPLOADED_SMALL_THUMB_NAME__ text-start text-[10px] text-[#7A7A7A] leading-[14.5px] overflow-hidden text-ellipsis">
                {extractAfterDoubleAsterisk(message.screenshots)}
              </p>
            </div>
          </div>
        )}
        {/* {message.screenshots?.map((attachment, index) => (
          <div
            key={index}
            className="flex items-center gap-2 mt-2 p-2 bg-[#30302F] rounded text-sm text-zinc-300"
          >
            <Paperclip className="w-4 h-4" />
            <span>{attachment}</span>
          </div>
        ))} */}
      </div>
    </div>
  );
}
