/**
 *@module MetamaskInstall-popup
 *@developer Shiv Pandey
 */

import React from "react";
import PopupWrapper from "../PopupWrapper";
import ConnectWallet from "pages/checkout/components/ConnectWallet";

const MetamaskInstall = ({ open = true, onClose, canClose }) => {
  return (
    <PopupWrapper open={open} onClose={onClose} canClose={canClose}>
      <div className="px-4 py-8 flex flex-col justify-center items-center gap-4 font-body bg-[#242424] rounded-[16px]">
        <ConnectWallet
          clickHandler={() =>
            window.open("https://metamask.io/download.html", "_blank")
          }
        />
      </div>
    </PopupWrapper>
  );
};

export default MetamaskInstall;
