import Loader from "components/Loader/Loader";
import { Message } from "./message/page";

export function ChatHistory({ title, messages, isLoading }) {
  return (
    <div className="space-y-4">
      <div className="bg-[#63636244] p-4 rounded-t-lg">
        <h3 className="text-lg font-medium text-white">Title: "{title}"</h3>
      </div>

      <div className="space-y-6 p-4 max-h-[70vh] overflow-y-auto showScrollbar custom-chat-container">
        {!isLoading ? (
          <>
            {messages.map((message) => (
              <Message key={message.id} message={message} />
            ))}
          </>
        ) : (
          <div className="__channel__details__container__main px-[20px] py-[20px]">
            <Loader />
            {/* <div className="__channel__details__container px-[15px] py-[28px] mt-[11px] rounded-[8px] bg-[#3A3A3A] flex md:gap-[21px]">
          </div> */}
          </div>
        )}
      </div>
    </div>
  );
}
