import React, { useState } from "react";
import ShadowFrame from "components/shadow-frame";
import { Link, NavLink } from "react-router-dom";

/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module User-My-Transaction-Component
 *@developer Aditya P
 */

// Default Transaction Data
const UserTransaction = [
  {
    transactionId: "TRX987654321",
    tr_name: "Apartment- Astral Suite",
    price: "USD 25.97",
    time: "12/10/2023, 12:39 PM",
    paymentMethod: "Rapyd",
    tr_status: true,
    tr_remark: null,
  },
  {
    transactionId: "TRX987654321",
    tr_name: "360 video- Skydiving into black hole",
    price: "USD 25.97",
    time: "12/10/2023, 12:39 PM",
    paymentMethod: "Credit",
    tr_status: false,
    tr_remark: "Refund successful",
  },
  {
    transactionId: "TRX987654321",
    tr_name: "Apartment- Astral Suite",
    price: "USD 25.97",
    time: "12/10/2023, 12:39 PM",
    paymentMethod: "Rapyd",
    tr_status_msg: "Success",
    tr_status: true,
    tr_remark: null,
  },
  {
    transactionId: "TRX987654321",
    tr_name: "Apartment- Astral Suite",
    price: "USD 25.97",
    time: "12/10/2023, 12:39 PM",
    paymentMethod: "Rapyd",
    tr_status_msg: "Success",
    tr_status: true,
    tr_remark: null,
  },
  {
    transactionId: "TRX987654321",
    tr_name: "Apartment- Astral Suite",
    price: "USD 25.97",
    time: "12/10/2023, 12:39 PM",
    paymentMethod: "Rapyd",
    tr_status_msg: "Success",
    tr_status: true,
    tr_remark: null,
  },
];

const MyTransaction = () => {
  const [isTransactionAvailable, setTransaction] = useState(true);

  return (
    <div className="__my_transaction__container__main mb-[100px] container mx-auto px-[100px] py-[60px] h-[100vh] max-[768px]:px-[15px] max-[768px]:py-[40px] max-[1280px]:px-[15px] relative">
      <ShadowFrame className="z-[0] w-[350px] md:w-[250px] h-[350px] md:h-[400px] rounded-[250px] md:rounded-[400px] left-[-10%] max-[768px]:left-[-50%] top-[50%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <ShadowFrame className="z-[0] w-[250px] md:w-[250px] h-[250px] md:h-[400px] rounded-[250px] md:rounded-[400px] right-[10%] max-[768px]:right-[50%] top-[60%] max-[768px]:top-[10%] translate-x-1/2 bottom-0 !bg-[#FFE9C9]" />
      <div className="__transaction__header__main relative">
        <h4 className="font-heading text-[24px] max-[768px]:text-[18px]">
          Transactions
        </h4>
        <h5 className="font-body text-[18px] font-medium pt-[40px] underline underline-offset-8 decoration-[#FBBC5E] max-[768px]:text-[15px] max-[768px]:pt-[25px]">
          All Transactions
        </h5>
        {isTransactionAvailable ? (
          <></>
        ) : (
          <hr className="mt-[24px] border-[0.1px] border-[#5A5A5A80]" />
        )}
      </div>
      <div className="__transaction__content__main h-[90%] relative">
        {isTransactionAvailable ? (
          <div className="__transaction__table__content_main w-[100%] mt-[24px] overflow-x-scroll">
            <table className="table-auto w-full">
              <thead className="bg-[#2E2E2E]">
                <tr className="[&>th]:text-[#FFFFFF99] [&>th]:font-normal [&>th]:text-[12px] [&>th]:text-left [&>th]:px-[10px] [&>th]:py-[10px]">
                  <th>Asset Category & name</th>
                  <th>Price</th>
                  <th>Date & time</th>
                  <th>Method</th>
                  <th>Transaction ID</th>
                  <th>Transaction Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {UserTransaction.map((_tr, index) => {
                  return (
                    <tr
                      key={index}
                      className="[&>td]:px-[10px] [&>td]:py-[18px] [&>td]:text-[14px] [&>td]:border-b [&>td]:border-b-[#5A5A5A80]"
                    >
                      <td className="text-[#FBBC5E] w-[30%] max-[768px]:w-[300px]">
                        <Link to="#">
                          <span className="hover:cursor-pointer hover:underline">
                            {_tr?.tr_name}
                          </span>
                        </Link>
                      </td>
                      <td>{_tr?.price}</td>
                      <td>{_tr?.time}</td>
                      <td>{_tr?.paymentMethod}</td>
                      <td>{_tr?.transactionId}</td>
                      <td>
                        {_tr?.tr_status ? (
                          <span className="text-[#56AA6D] font-medium">
                            Success
                          </span>
                        ) : (
                          <span className="text-[#FF6458] font-medium">
                            Failed
                          </span>
                        )}
                      </td>
                      <td>{_tr?.tr_remark === null ? "-" : _tr?.tr_remark}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="__transaction__empty__container flex flex-col justify-center items-center h-[100%] max-[768px]:justify-start max-[768px]:pt-[30px] max-[1280px]:pt-[30px] max-[1280px]:justify-start">
            <span className="text-[#FFFFFF99] text-[16px] font-medium font-body max-[768px]:text-[14px]">
              You don't have any transactions yet.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTransaction;
