/**
 * This file is part of FourthStar User Dashboard
 *(c) 2023 ShadowCast.Io <craig@shadowcast.io>
 *------------------------------------------------------
 *@module SingIn
 *@developer Sudhanshu <sudhanshut@instaacoders.com>
 */
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import GoogleLogin from "components/buttons/Google";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { isValidEmail, togglePasswordVisibility } from "utils/index";
import eyeOff from "assets/images/eye-off.svg";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, googleSigin } from "store/slicer/userAuth/authActions";
import { clearMsgState } from "store/slicer/userAuth";
import Alert from "components/popup/Alert/Alert";
import { useGoogleLogin } from "@react-oauth/google";
import ReactGA from "react-ga4";
import { useWebSocket } from "../../../services/socket/index";

/* This code is a React component for user sign-in with the option to switch between "User" and "Creator" accounts. It performs real-time validation for email, and password, including password strength checks. Users can toggle the visibility of the password, and the form is enabled for submission when all requirements are met.
 */

const SignIn = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [signInType, setSignInType] = useState("user");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const GOOGLE_ANALYTICS_MEASUREMENT_ID =
    process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  const isRequired = (value) => (value === "" ? false : true);
  // api
  const { loading, userInfo, userToken, error, success, successMsg } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UID = "userID" + JSON.parse(localStorage.getItem("userInfo"))?.id;
  const UMail =
    "userMail" + JSON.parse(localStorage.getItem("userInfo"))?.email;

  ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID, {
    gaOptions: {
      userId: UID,
    },
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertHeading, setAlertHeading] = useState("");
  const [successAlert, setSuccessAlert] = useState(true);
  console.log("success", alertMessage);

  const { setToken } = useWebSocket(); // Get the setToken function from context
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // called when alert popup is closed
  const onCloseAlert = () => {
    setAlertMessage("");
    setSuccessAlert("");
    setAlertHeading("");
    dispatch(clearMsgState());
  };

  // Function to toggle between user and creator sign-up
  const toggleSignInType = () => {
    setSignInType((prevSignInType) =>
      prevSignInType === "user" ? "creator" : "user"
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // Function to validate the form data
  const validateForm = () => {
    let isValid =
      checkEmail(formData.email) && checkPassword(formData.password);
    return isValid;
  };

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.trim(),
    });
    // if (name === "email") {
    //     if (!value.trim() && !value.trim().includes('@')) {
    //         checkEmail(value);
    //     }
    //     if (value.trim() && value.trim().includes('@')) {
    //         checkEmail(value);
    //     } else {
    //         setFormErrors({
    //             ...formErrors,
    //             email: ''
    //         });
    //     }
    // }
    // if (name === "password") {
    //     checkPassword(value);
    // }
  };
  // Function to check if the form is valid
  const isFormValid = () => {
    return isValidEmail(formData.email) && formData.password;
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      formData.email = formData.email.toLowerCase();
      dispatch(userLogin(formData));
    }
  };

  // checkEmail
  const checkEmail = (value) => {
    let valid = false;
    const fieldInput = value.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        email: "Email is required",
      });
    } else if (!isValidEmail(fieldInput)) {
      setFormErrors({
        ...formErrors,
        email: "Invalid Email Address",
      });
    } else {
      setFormErrors({
        ...formErrors,
        email: "",
      });
      valid = true;
    }
    return valid;
  };

  // checkPassword
  const checkPassword = (value) => {
    let valid = false;
    const fieldInput = value.trim();
    if (!isRequired(fieldInput)) {
      setFormErrors({
        ...formErrors,
        password: "Password is required",
      });
    } else {
      setFormErrors({
        ...formErrors,
        password: "",
      });
      valid = true;
    }
    return valid;
  };

  useEffect(() => {
    // removing it here to fix verify user and forgot password flow
    localStorage.removeItem("isForgot");
  }, []);

  // to check if session is expired and reload the page
  useEffect(() => {
    if (sessionStorage.getItem("sessionExpired")) {
      sessionStorage.removeItem("sessionExpired");
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setAlertMessage(error);
      setSuccessAlert(false);
    }
    if (userInfo && userToken) {
      // Google Analytics Sending login event
      // Set the userId for future hits
      ReactGA.set({ UID });
      // ReactGA.set({
      //   dimension1: "UMail",
      // });
      // Track the login event
      ReactGA.event({
        category: "User",
        action: "Login",
        label: UID,
      });

      // Set the token in WebSocket context
      setToken(userToken);
      // ReactGA.event({
      //   category: "User",
      //   action: "Login",
      //   label: UID,
      //   dimension1: "UMail", // Include the user name in the custom dimension
      // });
      // account not verified
      if (!userInfo?.isVerified) {
        return navigate("/otp-verification");
      }
      // onboarding not started, send to first onboarding page
      else if (userInfo?.statusFlag == 0) {
        return navigate("/add-details");
      }
      // 1st step of onboarding completed, send to next page
      else if (userInfo?.statusFlag == 1) {
        return navigate("/select-preferences");
      }
      // 2nd step of onboarding completed, send to next page
      else if (userInfo?.statusFlag == 2) {
        return navigate("/add-tags");
      }
      // redirect user to the page being visited after signing in
      else if (userInfo?.statusFlag == 3 && searchParams.get("ref")) {
        return navigate(searchParams.get("ref"), {
          replace: true,
        });
      }
      // onboarding is completed
      else if (userInfo?.statusFlag == 3) {
        return navigate("/");
      }
      // if(!userInfo?.dob){
      //     navigate('/add-details');
      // } else{
      //     navigate('/me');
      // }
    }
    // redirect user to otp verification if registration was successful
    // if (success && successMsg) {
    //     setTimeout(() => {
    //         dispatch(clearMsgState());
    //         let e = JSON.parse(localStorage.getItem("userInfo"));
    //         if(!e?.dob){
    //             navigate('/add-details');
    //         } else{
    //             navigate('/me');
    //         }

    //         setFormData({
    //             email: "",
    //             password: "",
    //         })
    //     }, 2000)

    // };
  }, [navigate, userInfo, userToken, error, success, successMsg]);

  // Google Sigin Setup and hooks
  const [googleUserData, setGoogleUserData] = useState([]);

  const GoogleLoginUser = useGoogleLogin({
    onSuccess: (response) => {
      console.log(response?.access_token);
      let googletoken = {
        access_token: response?.access_token,
      };
      // setGoogleUserData(response);
      dispatch(googleSigin(googletoken));
    },
    onError: (error) => console.log("Login Failed: ", error),
  });

  return (
    <>
      <Box
        className={`w-full max-w-[700px] rounded-lg  p-5 md:p-[32px] lg:p-[58px] ${
          isMobile ? "border border-[#363636] signin-form" : ""
        } `}
      >
        <Box className="text-white max-w-[500px] mx-auto">
          <h1 className="text-lg lg:text-xl text-center font-heading mb-[36px]">
            User Sign In
          </h1>
          <form className="auth-form mb-5" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="font-medium text-white/[.80] text-sm"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none autofill:bg-transparent ${
                    formErrors.email ? "!border-error" : ""
                  }`}
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={(e) => checkEmail(e.target.value)}
                />
                {formErrors.email && (
                  <span className="text-error text-sm">{formErrors.email}</span>
                )}
              </div>
            </div>
            <div className="mb-2">
              <label
                htmlFor="password"
                className="font-medium text-white/[.80] text-sm"
              >
                Password
              </label>
              <div className="mt-2 flex relative">
                <input
                  type="password"
                  name="password"
                  className={`rounded-lg w-full bg-transparent border border-white focus:border-[#f6f6f6] font-normal py-3 px-5 leading-normal font-semibold outline-none pr-[40px] autofill:bg-transparent ${
                    formErrors.password ? "!border-error" : ""
                  }`}
                  id="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  onBlur={(e) => checkPassword(e.target.value)}
                />
                <span
                  data-testid="PasswordVisibility"
                  className="vector absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer text-white"
                  onClick={(e) => togglePasswordVisibility(e)}
                >
                  <img src={eyeOff} alt="PasswordVisibility" />
                </span>
              </div>
              {formErrors.password && (
                <span className="text-error text-sm">
                  {formErrors.password}
                </span>
              )}
            </div>
            <div className="mb-6">
              <NavLink
                to="/forgot-password"
                className="text-sec hover:text-secDark font-semibold text-sm"
              >
                Forgot Password?
              </NavLink>
            </div>
            <button
              type="submit"
              className="font-bold rounded-lg btn-gradient w-full text-black py-3 px-5 flex justify-center"
              disabled={!isFormValid() || loading}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Sign In"
              )}
            </button>
          </form>

          <div className="divider flex gap-x-2 mb-5">
            <span className="text-sm font-medium">Or</span>
          </div>

          <GoogleLogin
            type="button"
            onClick={GoogleLoginUser}
            text="Continue with Google"
            className="mb-6"
          />

          <p className="mb-5 text-sm text-center">
            Don't have an account?{" "}
            <NavLink
              to="/signup"
              className="text-sec hover:text-secDark font-medium"
            >
              Create account
            </NavLink>
          </p>

          <p className="text-center text-sm text-sec hover:text-secDark font-bold">
            <button onClick={toggleSignInType}>
              {signInType === "creator" ? (
                "Sign In as User"
              ) : (
                <NavLink
                  to={`${process.env.REACT_APP_CREATOR_WEB_URL}/auth/login`}
                >
                  Sign in as Creator
                </NavLink>
              )}
            </button>
          </p>
        </Box>
      </Box>
      {alertMessage && (
        <Alert
          open={Boolean(alertMessage)}
          onClose={onCloseAlert}
          message={alertMessage}
          heading={alertHeading}
          isSuccess={successAlert}
        />
      )}
    </>
  );
};

export default SignIn;
