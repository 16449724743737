import React from "react";
import MetaMaskIcon from "assets/images/metamask-icon.png";

const ConnectWallet = ({ clickHandler }) => {
  return (
    <div>
      <h2 className="font-normal text-[20px] leading-[30px] text-center font-heading">
        Connect MetaMask Wallet
      </h2>
      <div className="mt-8 w-fit rounded-full border border-[#898989] p-4 mx-auto">
        <img
          className="w-[56px] h-[52px] object-contain"
          src={MetaMaskIcon}
          alt="metamask-icon"
        />
      </div>
      <p className="mt-12 text-center font-medium leading-[24px] text-[15px] font-body">
        <span className="font-semibold">Step:1</span> Download and install the
        Meta Mask extension for your web browser.
      </p>
      <p className="mt-4 text-center font-medium leading-[24px] text-[15px] font-body">
        <span className="font-semibold">Step:2</span> Follow the on-screen
        instructions to set up your Meta Mask wallet
      </p>

      <button
        className="mt-12 btn-gradient block mx-auto text-black font-bold text-[16px] leading-[19.5px] rounded-[6px] px-6 py-3 text-center"
        onClick={clickHandler}
      >
        Get Started
      </button>
    </div>
  );
};

export default ConnectWallet;
